"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTypography = void 0;
const font_size_1 = require("./font-size");
const font_style_1 = require("./font-style");
const font_weight_1 = require("./font-weight");
const font_variant_1 = require("./font-variant");
const letter_spacing_1 = require("./letter-spacing");
const text_align_1 = require("./text-align");
const text_align_vertical_1 = require("./text-align-vertical");
const text_decoration_style_1 = require("./text-decoration-style");
const text_decoration_line_1 = require("./text-decoration-line");
const text_transform_1 = require("./text-transform");
const text_color_1 = require("./text-color");
const buildTypography = () => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, font_size_1.fontSizes), font_style_1.fontStyles), font_weight_1.fontWeights), font_variant_1.fontVariants), letter_spacing_1.letterSpacing), text_align_1.textAlign), text_align_vertical_1.textAlignVertical), text_decoration_style_1.textDecorationStyle), text_decoration_line_1.textDecorationLine), text_transform_1.textTransform), text_color_1.buildTextColorStyles());
};
exports.buildTypography = buildTypography;
