"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildBorderRadiusStyles = exports.borderRadiusPositions = exports.radiusDimension = void 0;
exports.radiusDimension = {
    '-none': 0,
    '-sm': 2,
    '': 4,
    '-md': 6,
    '-lg': 8,
    '-xl': 12,
    '-2xl': 16,
    '-3xl': 24,
    '-full': 9999,
};
exports.borderRadiusPositions = [
    '',
    '-te',
    '-tl',
    '-tr',
    '-ts',
    '-be',
    '-bl',
    '-br',
    '-bs',
];
const borderRadiusPositionsPair = {
    '': 'borderRadius',
    '-be': 'borderBottomEndRadius',
    '-br': 'borderBottomRightRadius',
    '-bl': 'borderBottomLeftRadius',
    '-bs': 'borderBottomStartRadius',
    '-te': 'borderTopEndRadius',
    '-tr': 'borderTopRightRadius',
    '-tl': 'borderTopLeftRadius',
    '-ts': 'borderTopStartRadius',
};
const buildBorderRadiusStyles = () => {
    const styles = {};
    exports.borderRadiusPositions.forEach((p) => {
        Object.keys(exports.radiusDimension).forEach((dk) => {
            styles[`rounded${p}${dk}`] = {
                [borderRadiusPositionsPair[p]]: exports.radiusDimension[dk],
            };
        });
    });
    return styles;
};
exports.buildBorderRadiusStyles = buildBorderRadiusStyles;
