"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexWrap = void 0;
exports.flexWrap = {
    'flex-wrap': {
        flexWrap: 'wrap',
    },
    'flex-nowrap': {
        flexWrap: 'nowrap',
    },
    'flex-wrap-reverse': {
        flexWrap: 'wrap-reverse',
    },
};
