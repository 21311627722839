"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildMaxHeights = void 0;
const sizes_1 = require("./sizes");
const buildMaxHeights = () => {
    const maxHeight = {};
    Object.keys(sizes_1.sizes).forEach((s) => {
        maxHeight[`max-h-${s}`] = {
            maxHeight: sizes_1.sizes[s],
        };
    });
    return maxHeight;
};
exports.buildMaxHeights = buildMaxHeights;
