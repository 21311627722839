"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textDecorationLine = void 0;
exports.textDecorationLine = {
    'line-through': {
        textDecorationLine: 'line-through',
    },
    underline: {
        textDecorationLine: 'underline',
    },
    'no-text-line': {
        textDecorationLine: 'none',
    },
    'line-through-underline': {
        textDecorationLine: 'line-through-underline',
    },
};
