"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textTransform = void 0;
exports.textTransform = {
    uppercase: {
        textTransform: 'uppercase',
    },
    lowercase: {
        textTransform: 'lowercase',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    'normal-case': {
        textTransform: 'none',
    },
};
