"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorStyleBuilder = void 0;
const colors_1 = require("../theme/colors");
const colorStyleBuilder = (classPrefix, styleName) => {
    const styles = {};
    Object.keys(colors_1.colors).forEach((ck) => {
        if (typeof colors_1.colors[ck] === 'object') {
            Object.keys(colors_1.colors[ck]).forEach((cs) => {
                styles[`${classPrefix}-${ck}-${cs}`] = {
                    [styleName]: colors_1.colors[ck][cs],
                };
            });
        }
        else {
            styles[`${classPrefix}-${ck}`] = {
                [styleName]: colors_1.colors[ck],
            };
        }
    });
    return styles;
};
exports.colorStyleBuilder = colorStyleBuilder;
