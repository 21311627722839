"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.position = void 0;
exports.position = {
    'relative': {
        position: 'relative',
    },
    'absolute': {
        position: 'absolute',
    }
};
