"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textAlign = void 0;
exports.textAlign = {
    'text-left': {
        textAlign: 'left',
    },
    'text-center': {
        textAlign: 'center',
    },
    'text-right': {
        textAlign: 'right',
    },
    'text-justify': {
        textAlign: 'justify',
    },
    'text-auto': {
        textAlign: 'auto',
    },
};
