"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainStyles = exports.composeStyles = void 0;
const flex_style_1 = require("../styles/flex/flex-style");
const layout_1 = require("../styles/layout/layout");
const heights_1 = require("../styles/sizing/heights");
const max_height_1 = require("../styles/sizing/max-height");
const max_width_1 = require("../styles/sizing/max-width");
const min_height_1 = require("../styles/sizing/min-height");
const min_width_1 = require("../styles/sizing/min-width");
const sizes_1 = require("../styles/sizing/sizes");
const widths_1 = require("../styles/sizing/widths");
const margins_1 = require("../styles/spacing/margins");
const paddings_1 = require("../styles/spacing/paddings");
const spaces_1 = require("../styles/spacing/spaces");
const typography_1 = require("../styles/typography/typography");
const view_1 = require("../styles/view/view");
const colors_1 = require("../theme/colors");
const composeStyles = () => {
    sizes_1.mergeSizes();
    spaces_1.mergeSpaces();
    colors_1.buildColors();
    const styles = [
        heights_1.buildHeights(),
        widths_1.buildWidths(),
        layout_1.buildLayout(),
        max_height_1.buildMaxHeights(),
        min_height_1.buildMinHeights(),
        max_width_1.buildMaxWidths(),
        min_width_1.buildMinWidths(),
        margins_1.buildMargins(),
        paddings_1.buildPaddings(),
        flex_style_1.buildFlex(),
        typography_1.buildTypography(),
        view_1.buildView(),
    ];
    exports.mainStyles = styles.reduce((pv, cv) => (Object.assign(Object.assign({}, pv), cv)), {});
};
exports.composeStyles = composeStyles;
if (!exports.mainStyles) {
    exports.composeStyles();
}
