"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexShrink = void 0;
exports.flexShrink = {
    'flex-shrink-0': {
        flexShrink: 0,
    },
    'flex-shrink': {
        flexShrink: 1,
    },
};
