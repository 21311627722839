"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexGrow = void 0;
exports.flexGrow = {
    'flex-grow-0': {
        flexGrow: 0,
    },
    'flex-grow': {
        flexGrow: 1,
    },
};
