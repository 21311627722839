"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.borderWidths = void 0;
exports.borderWidths = {
    'border-0': {
        borderWidth: 0,
    },
    'border-2': {
        borderWidth: 2,
    },
    'border-4': {
        borderWidth: 4,
    },
    'border-8': {
        borderWidth: 8,
    },
    border: {
        borderWidth: 1,
    },
    'border-t-0': {
        borderTopWidth: 0,
    },
    'border-t-2': {
        borderTopWidth: 2,
    },
    'border-t-4': {
        borderTopWidth: 4,
    },
    'border-t-8': {
        borderTopWidth: 8,
    },
    'border-t': {
        borderTopWidth: 1,
    },
    'border-b-0': {
        borderBottomWidth: 0,
    },
    'border-b-2': {
        borderBottomWidth: 2,
    },
    'border-b-4': {
        borderBottomWidth: 4,
    },
    'border-b-8': {
        borderBottomWidth: 8,
    },
    'border-b': {
        borderBottomWidth: 1,
    },
    'border-l-0': {
        borderLeftWidth: 0,
    },
    'border-l-2': {
        borderLeftWidth: 2,
    },
    'border-l-4': {
        borderLeftWidth: 4,
    },
    'border-l-8': {
        borderLeftWidth: 8,
    },
    'border-l': {
        borderLeftWidth: 1,
    },
    'border-r-0': {
        borderRightWidth: 0,
    },
    'border-r-2': {
        borderRightWidth: 2,
    },
    'border-r-4': {
        borderRightWidth: 4,
    },
    'border-r-8': {
        borderRightWidth: 8,
    },
    'border-r': {
        borderRightWidth: 1,
    },
    'border-s-0': {
        borderStartWidth: 0,
    },
    'border-s-2': {
        borderStartWidth: 2,
    },
    'border-s-4': {
        borderStartWidth: 4,
    },
    'border-s-8': {
        borderStartWidth: 8,
    },
    'border-s': {
        borderStartWidth: 1,
    },
    'border-e-0': {
        borderEndWidth: 0,
    },
    'border-e-2': {
        borderEndWidth: 2,
    },
    'border-e-4': {
        borderEndWidth: 4,
    },
    'border-e-8': {
        borderEndWidth: 8,
    },
    'border-e': {
        borderEndWidth: 1,
    },
};
