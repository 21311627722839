"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flex = void 0;
exports.flex = {
    'flex-1': {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
    },
    'flex-auto': {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
    },
    'flex-initial': {
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 'auto',
    },
    'flex-none': {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'auto',
    },
};
