"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexDirections = void 0;
exports.flexDirections = {
    'flex-row': {
        flexDirection: 'row',
    },
    'flex-row-reverse': {
        flexDirection: 'row-reverse',
    },
    'flex-col': {
        flexDirection: 'column',
    },
    'flex-col-reverse': {
        flexDirection: 'column-reverse',
    },
};
