"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textDecorationStyle = void 0;
exports.textDecorationStyle = {
    'text-decoration-dashed': {
        textDecorationStyle: 'dashed',
    },
    'text-decoration-dotted': {
        textDecorationStyle: 'dotted',
    },
    'text-decoration-double': {
        textDecorationStyle: 'double',
    },
    'text-decoration-solid': {
        textDecorationStyle: 'solid',
    },
};
