"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTextColorStyles = void 0;
const color_style_builder_1 = require("../../helpers/color-style-builder");
const buildTextColorStyles = () => {
    let textColorsStyle = {};
    textColorsStyle = color_style_builder_1.colorStyleBuilder('text', 'color');
    return textColorsStyle;
};
exports.buildTextColorStyles = buildTextColorStyles;
