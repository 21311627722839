"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.letterSpacing = void 0;
exports.letterSpacing = {
    'tracking-tighter': {
        letterSpacing: -1,
    },
    'tracking-tight': {
        letterSpacing: -0.5,
    },
    'tracking-normal': {
        letterSpacing: 0,
    },
    'tracking-wide': {
        letterSpacing: 1,
    },
    'tracking-wider': {
        letterSpacing: 2,
    },
    'tracking-widest': {
        letterSpacing: 4,
    },
};
