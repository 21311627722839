"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alignContent = void 0;
exports.alignContent = {
    'content-center': {
        alignContent: 'center',
    },
    'content-start': {
        alignContent: 'flex-start',
    },
    'content-end': {
        alignContent: 'flex-end',
    },
    'content-between': {
        alignContent: 'space-between',
    },
    'content-around': {
        alignContent: 'space-around',
    },
    'content-stretch': {
        alignContent: 'stretch',
    },
};
