"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resizeMode = void 0;
exports.resizeMode = {
    'resize-center': {
        resizeMode: 'center',
    },
    'resize-contain': {
        resizeMode: 'contain',
    },
    'resize-cover': {
        resizeMode: 'cover',
    },
    'resize-repeat': {
        resizeMode: 'repeat',
    },
    'resize-stretch': {
        resizeMode: 'stretch',
    },
};
