"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontVariants = void 0;
exports.fontVariants = {
    'lining-nums': {
        fontVariant: ['lining-nums'],
    },
    'oldstyle-nums': {
        fontVariant: ['oldstyle-nums'],
    },
    'proportional-nums': {
        fontVariant: ['proportional-nums'],
    },
    'small-caps': {
        fontVariant: ['small-caps'],
    },
    'tabular-nums': {
        fontVariant: ['tabular-nums'],
    },
};
