"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontWeights = void 0;
exports.fontWeights = {
    'font-thin': {
        fontWeight: '100',
    },
    'font-extralight': {
        fontWeight: '200',
    },
    'font-light': {
        fontWeight: '300',
    },
    'font-normal': {
        fontWeight: '400',
    },
    'font-medium': {
        fontWeight: '500',
    },
    'font-semibold': {
        fontWeight: '600',
    },
    'font-bold': {
        fontWeight: '700',
    },
    'font-extrabold': {
        fontWeight: '800',
    },
    'font-black': {
        fontWeight: '900',
    },
};
