"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zIndex = void 0;
exports.zIndex = {
    ['z-0']: {
        zIndex: 0,
    },
    ['z-10']: {
        zIndex: 10,
    },
    ['z-20']: {
        zIndex: 20,
    },
    ['z-30']: {
        zIndex: 30,
    },
    ['z-40']: {
        zIndex: 40,
    },
    ['z-50']: {
        zIndex: 50,
    },
};
