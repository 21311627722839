"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.justifyContent = void 0;
exports.justifyContent = {
    'justify-start': {
        justifyContent: 'flex-start',
    },
    'justify-end': {
        justifyContent: 'flex-end',
    },
    'justify-center': {
        justifyContent: 'center',
    },
    'justify-between': {
        justifyContent: 'space-between',
    },
    'justify-around': {
        justifyContent: 'space-around',
    },
    'justify-evenly': {
        justifyContent: 'space-evenly',
    },
};
