"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildBackgroundColorStyles = void 0;
const color_style_builder_1 = require("../../helpers/color-style-builder");
const buildBackgroundColorStyles = () => {
    let backgroundColorsStyle = {};
    backgroundColorsStyle = color_style_builder_1.colorStyleBuilder('bg', 'backgroundColor');
    return backgroundColorsStyle;
};
exports.buildBackgroundColorStyles = buildBackgroundColorStyles;
