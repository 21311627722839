"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.borderStyles = void 0;
exports.borderStyles = {
    'border-solid': {
        borderStyle: 'solid',
    },
    'border-dashed': {
        borderStyle: 'dashed',
    },
    'border-dotted': {
        borderStyle: 'dotted',
    },
};
