"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildView = void 0;
const background_color_1 = require("./background-color");
const border_color_1 = require("./border-color");
const border_radius_1 = require("./border-radius");
const border_style_1 = require("./border-style");
const border_width_1 = require("./border-width");
const buildView = () => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, background_color_1.buildBackgroundColorStyles()), border_radius_1.buildBorderRadiusStyles()), border_color_1.buildBorderColorStyles()), border_width_1.borderWidths), border_style_1.borderStyles);
};
exports.buildView = buildView;
