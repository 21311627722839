"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textAlignVertical = void 0;
exports.textAlignVertical = {
    'text-vertical-auto': {
        textAlignVertical: 'auto',
    },
    'text-vertical-bottom': {
        textAlignVertical: 'bottom',
    },
    'text-vertical-center': {
        textAlignVertical: 'center',
    },
    'text-vertical-top': {
        textAlignVertical: 'top',
    },
};
