"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildLayout = void 0;
const display_1 = require("./display");
const overflow_1 = require("./overflow");
const position_1 = require("./position");
const resize_mode_1 = require("./resize-mode");
const top_bottom_left_right_1 = require("./top-bottom-left-right");
const z_index_1 = require("./z-index");
const buildLayout = () => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, resize_mode_1.resizeMode), overflow_1.overflow), position_1.position), z_index_1.zIndex), top_bottom_left_right_1.buildTopBottomLeftRight()), display_1.display);
};
exports.buildLayout = buildLayout;
