"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildBorderColorStyles = exports.borderColorPositions = void 0;
const color_style_builder_1 = require("../../helpers/color-style-builder");
exports.borderColorPositions = [
    '',
    '-e',
    '-s',
    '-l',
    '-r',
    '-t',
    '-b',
];
const borderColorPositionsPair = {
    '': 'borderColor',
    '-e': 'borderEndColor',
    '-s': 'borderStartColor',
    '-r': 'borderRightColor',
    '-l': 'borderLeftColor',
    '-b': 'borderBottomColor',
    '-t': 'borderTopColor',
};
const buildBorderColorStyles = () => {
    let borderColorStyles = {};
    exports.borderColorPositions.forEach((p) => {
        const borderColors = color_style_builder_1.colorStyleBuilder(`border${p}`, borderColorPositionsPair[p]);
        borderColorStyles = Object.assign(Object.assign({}, borderColorStyles), borderColors);
    });
    return borderColorStyles;
};
exports.buildBorderColorStyles = buildBorderColorStyles;
