"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.display = void 0;
exports.display = {
    flex: {
        display: 'flex',
    },
    none: {
        display: 'none',
    },
};
