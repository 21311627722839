"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontStyles = void 0;
exports.fontStyles = {
    italic: {
        fontStyle: 'italic',
    },
    'non-italic': {
        fontStyle: 'normal',
    },
};
