"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.overflow = void 0;
exports.overflow = {
    'overflow-visible': {
        overflow: 'visible',
    },
    'overflow-hidden': {
        overflow: 'hidden',
    },
};
